import React, { useEffect, useState, } from "react";
import { Container, Row, Col, Form, Button, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {isMobile} from 'react-device-detect';
import { QRCode } from 'react-qrcode-logo';
import Disclosures from "./disclosures.jsx";
import ConsentCommunications from "./consentCommunication.jsx";
import { IdValidation, FaceValidation } from 'idmission-web-sdk';

const serverConfig = require("../config/server.js");

const FormSubmit = ({ prevStep, nextStep, token, curPageName, stepNum, stepLength, handleLogout }) => {

  const navigate = useNavigate();

  const[isLoading, setIsLoading] = useState(true);

  const[ppChecks, setPPChecks] = useState({});
  const[inputs, setInputs] = useState({});
  const[address, setAddress] = useState({});
  const[groupData, setGroupData] = useState({});
  const[sectionData, setSectionData] = useState([]);

  const[showMailingAddr, setShowMailingAddr] = useState(false);
  const[mailingAddrChecked, setMailingAddrChecked] = useState(false);
  const[isMailAddrRequired, setIsMailAddrRequired] = useState(false);
  const[isMailAddrDisabled, setIsMailAddrDisabled] = useState(true);

  const[showAltUSAddr, setShowAltUSAddr] = useState(false);
  const[altUSAddrChecked, setAltUSAddrChecked] = useState(false);
  const[isAltUSAddrRequired, setIsAltUSAddrRequired] = useState(false);
  const[isAltUSAddrDisabled, setIsAltUSAddrDisabled] = useState(true);

  const[companyInfo, setCompanyInfo] = useState(false);
  const[isCompanyInfoRequired, setIsCompanyInfoRequired] = useState(false);
  const[isCompanyInfoDisabled, setIsCompanyInfoDisabled] = useState(true);

  const[otherSources, setOtherSources] = useState(false);
  const[isOthersRequired, setIsOthersRequired] = useState(false);
  const[isOthersDisabled, setIsOthersDisabled] = useState(true);

  /* const[isReadOnly, setIsReadOnly] = useState(false); */
  const[nextDisabled, setNextDisabled] = useState(false);
  const[prevDisabled, setPrevDisabled] = useState(false);
  const[showQrCode, setShowQrCode] = useState(false);

  const[countryLookup, setCountryLookup] = useState([]);
  const[stateLookup, setStateLookup] = useState([]);
  const[cityLookup, setCityLookup] = useState([]);
  const[stateMALookup, setStateMALookup] = useState([]);
  const[cityMALookup, setCityMALookup] = useState([]);
  const[stateUSLookup, setStateUSLookup] = useState([]);
  const[cityUSLookup, setCityUSLookup] = useState([]);

  const[countryCitizenship, setCountryCitizenship] = useState("");
  /* const[FTINChecked, setFTINChecked] = useState(false);
  const[taxpayerIdentificationNumberNull, setTaxpayerIdentificationNumberNull] = useState(true);
  const [signedTimestamp, setSignedTimestamp] = useState("");
  const [verifiedOTP, setVerifiedOTP] = useState(false);
  const [OTPErrorMsg, setOTPErrorMsg] = useState("");
  const [otp, setOtp] = useState(""); */

  var date18yearsago = new Date();
  date18yearsago.setTime(date18yearsago.setFullYear(date18yearsago.getFullYear()-18));
  const max18years = date18yearsago.toISOString().split('T')[0];

  const getGroupData = () => {
    console.log(token);
    var formdata = {token:token, entity_group:curPageName};
    console.log("formdata:", formdata);

    axios.post(serverConfig.api_base_url + "customer_entity_group_details_get",formdata)
    .then(response=>{
      setIsLoading(false);
      setPrevDisabled(false);
      console.log('customer_entity_group_details_get', response);
      console.log(response.data.items);
      if(response.data.items !== null){
        let res = response.data.items;
        console.log(res);
        console.log(res.group_sections);
        res.group_sections.sort(function(a,b){
          return a.section_seq_no - b.section_seq_no;
        });
        setGroupData(res);
        setSectionData(res.group_sections);

        setTimeout(() => {
          let ph_no = res.group_sections.filter(section => section.section_type === 'mobile_number');
          console.log(ph_no.length);
          if(ph_no.length > 0){
            let cc_name = ph_no[0].section_entities.filter(entities => entities.entity_name === "countryCode");
            if(cc_name[0].entity_data){
              const select = document.getElementsByName(`${cc_name[0].entity_name}`);
              if(select){
                console.log(select, document.getElementsByName('countryCode'));
                const showValueOption = select[0].querySelector('.show-value');
                showValueOption.innerText = cc_name[0].entity_data;
                showValueOption.value = cc_name[0].entity_data;
                document.getElementById(`${cc_name[0].entity_name}`).selectedIndex = 0;
              }
            }
          }
        }, 100);

        let ma = res.group_sections.filter(section => section.section_name === 'mailing_address');
        if(ma.length > 0){
          if(ma[0].section_entities[0].entity_data){
            setShowMailingAddr(true);
            setIsMailAddrRequired(true);
            setIsMailAddrDisabled(false);
            setMailingAddrChecked(true);
          } else {
            setShowMailingAddr(false);
            setIsMailAddrRequired(false);
            setIsMailAddrDisabled(true);
            setMailingAddrChecked(false);
          }
        }

        let pp = res.group_sections.filter(section => section.section_name === "passport_details");
        let sd = res.group_sections.filter(section => section.section_name === "secondary_document_details");
        if(pp.length > 0 && sd.length > 0){
          let pp_img_name = pp[0].section_entities.filter(entities => entities.entity_name === "passportImg");
          let selfie_pp = pp[0].section_entities.filter(entities => entities.entity_name === "captureSelfiePassportChecks");
          let sd_img_name = sd[0].section_entities.filter(entities => entities.entity_name === "secondaryDocImg");
          console.log(inputs, selfie_pp[0]?.entity_data);
          if(!isMobile){
            setNextDisabled(true);
          } else {
            setInputs({
              ...inputs,
              passportImg: pp_img_name[0]?.entity_data,
              secondaryDocImg: sd_img_name[0]?.entity_data,
            });
            setInputs((stateInputs) => {
              console.log(inputs, stateInputs);
              return stateInputs;
            });

            setPPChecks(JSON.parse(selfie_pp[0]?.entity_data));

            if(pp_img_name[0].entity_data && sd_img_name[0].entity_data){
              setNextDisabled(false);
            } else {
              setNextDisabled(true);
            }
          }
        }

    let country_citizenship = res.group_sections.filter(section => section.section_name === "w8ben_part1");
        if(country_citizenship.length > 0){
          let country_citizenship_data = country_citizenship[0].section_entities.filter(entities => entities.entity_name === "countryOfCitizenship");
          if(country_citizenship_data[0].entity_data){
            setCountryCitizenship(country_citizenship_data[0].entity_data);
          } else {
            setCountryCitizenship("");
          }
        }
      } else {
        setGroupData({});
        setSectionData([]);
      }
    })
    .catch(err=>console.log("error is",err));
    setTimeout(() => {//remove spinner if more than 10s after page load
      console.log('loading');
      setIsLoading(false);
    }, 10000);
  }

  const handleSetCountryLookup = () => {
    //set country dropdown
    axios.post(serverConfig.api_base_url + "get_lookup_values",{entity_name:"country"})
    .then(response=>{
      console.log("Country lookup:", response);
      if (response.data != null && response.data.data != null) {
        console.log("Country data:", response.data.data);
        setCountryLookup(response.data.data.sort((a, b) => a.value.localeCompare(b.value)));
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    setIsLoading(true);
    setInputs({});
    setAddress({});
    setGroupData({});
    setSectionData([]);
    getGroupData();
    
    //setOtherSources(false);
    setShowQrCode(false);
    setNextDisabled(false);
    console.log(curPageName, nextDisabled);
    if(curPageName === 'personal_information'){
      handleSetCountryLookup();
      //handleSetStateUSLookup();
    } else if(curPageName === 'documentation'){
      handleSetCountryLookup();
      setNextDisabled(true);
      if(isMobile) {
        setShowQrCode(false);
      } else {
        setShowQrCode(true);
      }

      console.log(inputs, 'inputs');

      axios.post(serverConfig.api_base_url + "get_idmission_token_v2")
      .then(response=>{
        console.log(response);
        if(response.data.access_token) {
          setIdmToken(response.data.access_token);
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }

  },[curPageName]);

  const handleDiffMailAddr = (event) => {
    if (event.target.checked) {
      setShowMailingAddr(true);
      setIsMailAddrRequired(true);
      setIsMailAddrDisabled(false);
      setMailingAddrChecked(true);
    } else {
      setShowMailingAddr(false);
      setIsMailAddrRequired(false);
      setIsMailAddrDisabled(true);
      setMailingAddrChecked(false);

      if(curPageName === 'personal_information'){
        document.getElementsByName('addressLine1MailingAddress')[0].value = '';
        document.getElementsByName('addressLine2MailingAddress')[0].value = '';
        document.getElementsByName('cityMailingAddress')[0].value = '';
        document.getElementsByName('stateMailingAddress')[0].value = '';
        document.getElementsByName('zipcodeMailingAddress')[0].value = '';
        document.getElementsByName('countryMailingAddress')[0].value = '';
        setInputs({
          ...inputs,
          addressLine1MailingAddress:'',
          addressLine2MailingAddress:'',
          cityMailingAddress:'',
          stateMailingAddress:'',
          zipcodeMailingAddress:'',
          countryMailingAddress:'',
        });
      }
    }
  }

  const handleOptions = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const list = event.target.getAttribute('list');
    const label = event.target.parentElement.querySelector('label').innerText.replace('*','');

    if(!Array.from(document.querySelector('#'+list).options).map(o => o.innerText).includes(value)){
      if(value !== ''){
        alert('Entered '+ label +' option does not exist');
        document.getElementsByName(name)[0].value = '';
        setTimeout(() => {
          document.getElementsByName(name)[0].focus();
          document.getElementsByName(name)[0].focus({focusVisible: true});
        }, 100);
      }
    }
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    console.log(inputs, 'INITIAL VALUE');
    const newData={...inputs};
    console.log(newData, 'INITIAL VALUE NEW DATA')

    if (name==="countryCode") {
      const select = event.target;
      const showValueOption = select.querySelector('.show-value');
      if (value === '') {
        select.selectedIndex = 0;
      }
      showValueOption.innerText = value;
      showValueOption.value = value;
      select.selectedIndex = 0;
    } else if(name==="country"){
      setStateLookup([]);
      setCityLookup([]);
      newData['state']='';
      document.getElementsByName('state')[0].value = '';
      newData['city']='';
      document.getElementsByName('city')[0].value = '';
      setInputs(newData);
      //getFormdata.state ='';
      //getFormdata.city ='';
      axios.post(serverConfig.api_base_url + "get_lookup_values",{entity_name:"state", entity_parent_value:value})
      .then(response=>{
        setStateLookup(response.data.data.sort((a, b) => a.value.localeCompare(b.value)));
      })
      .catch(err=>console.log("error is",err));
    } else if(name==="state"){
      setCityLookup([]);
      newData['city']='';
      document.getElementsByName('city')[0].value = '';
      setInputs(newData);
      axios.post(serverConfig.api_base_url + "get_lookup_values",{entity_name:"city", entity_parent_value:value})
      .then(response=>{
        setCityLookup(response.data.data.sort((a, b) => a.value.localeCompare(b.value)));
      })
      .catch(err=>console.log("error is",err));
      console.log('state value',value);
    } else if(name==="countryMailingAddress"){
      setStateMALookup([]);
      setCityMALookup([]);
      newData['stateMailingAddress']='';
      document.getElementsByName('stateMailingAddress')[0].value = '';
      newData['cityMailingAddress']='';
      document.getElementsByName('cityMailingAddress')[0].value = '';
      setInputs(newData);
      axios.post(serverConfig.api_base_url + "get_lookup_values",{entity_name:"state", entity_parent_value:value})
      .then(response=>{
        setStateMALookup(response.data.data.sort((a, b) => a.value.localeCompare(b.value)));
      })
      .catch(err=>console.log("error is",err));
    } else if(name==="stateMailingAddress"){
      setCityMALookup([]);
      newData['cityMailingAddress']='';
      document.getElementsByName('cityMailingAddress')[0].value = '';
      setInputs(newData);
      axios.post(serverConfig.api_base_url + "get_lookup_values",{entity_name:"city", entity_parent_value:value})
      .then(response=>{
        setCityMALookup(response.data.data.sort((a, b) => a.value.localeCompare(b.value)));
      })
      .catch(err=>console.log("error is",err));
    } else if(name==="citizenship"){
      let options = document.querySelectorAll('#citizenship option');
      for(var i = 0; i < options.length; i++) {
        var option = options[i];
        if(option.innerText === value) {
          console.log(option.getAttribute('data-value'));
          newData['citizenCountryCode']=option.getAttribute('data-value');
          break;
        }
      }
    }
    newData[name]=value;
    console.log(newData);
    setInputs(newData);
    console.log('inputs', inputs);
  }

  const handleDelete = (isEditable, event) => {
    console.log('handleDelete');
      if(isEditable){
      var key = event.keyCode || event.charCode;
      let custDataType = event.target.dataset.customDatatype;

      if( key == 8 || key == 46 ){
        if(event.target.value === ''){
          setInputs(values => ({...values, [event.target.name]: event.target.value}))
        }
      } else if(!(key >= 48 && key <=57) && key!==9 && key!==13 && custDataType === "amount"){
        event.preventDefault();
      }

      if(event.target.type==='number'){
        if(["e", "E", "+", "-"].includes(event.key)){
          event.preventDefault();
        }
      }
    } else {
      event.preventDefault();
    }
  }

  const submitFormData = (e) => {
    e.preventDefault();
    setNextDisabled(true);
    console.log(inputs);
    console.log(address);
    console.log({...inputs, ...address});
    var formdata;
    if(document.querySelector('button[type="submit"]').textContent === 'Submit'){
      formdata = {token:token, entity_group:curPageName, items:{submitted: true}};
    } else {
      formdata = {token:token, entity_group:curPageName, items:{...inputs, ...address}};
    }

    if(curPageName === 'documentation'){
      var inputVal = {
        expirationDate: document.querySelector('input[name="expirationDate"]').value,
        countryOfIssuance: document.querySelector('input[name="countryOfIssuance"]').value,
        IssuanceDate: document.querySelector('input[name="IssuanceDate"]').value,
        passportNumber: document.querySelector('input[name="passportNumber"]').value,
      }
      formdata = {token:token, entity_group:curPageName, items:inputVal};
    }

    console.log(formdata);
    axios.post(serverConfig.api_base_url + "customer_entity_group_details_set",formdata)
    .then(response=>{
      console.log('customer_entity_group_details_set response', response);
      if(response.data.status === 200) {
        if(document.querySelector('button[type="submit"]').textContent === 'Submit'){
          setShowSubmitModal(true);
        } else {
          nextStep();
        }
      } else if(response.data.status === 407){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  };

  //personal_information
  const handlePlaceSelected = (place) => {
    const Address = {};
    console.log(inputs);
    console.log({...inputs});
    const newData={...inputs}
    console.log(newData);
    console.log(place);
    let placeArr = place.address_components;
    console.log(placeArr);
    let country = placeArr.filter(el => el.types.includes('country'));
    if(country.length > 0){
      console.log('country', country[0].long_name);
      document.getElementsByName('country')[0].value = country[0].long_name;
      Address['country']=country[0].long_name;
    }
    let state = placeArr.filter(el => el.types.includes('administrative_area_level_1'));
    if(state.length > 0){
      console.log('state', state.length);
      document.getElementsByName('state')[0].value = state[0].long_name;
      Address['state']=state[0].long_name;
    }
    let city = placeArr.filter(el => el.types.includes('locality'));
    if(city.length > 0){
      console.log(city.length, 'CITY')
      console.log('city', city[0].long_name);
      document.getElementsByName('city')[0].value = city[0].long_name;
      Address['city']=city[0].long_name;
    }
    let postalCode = placeArr.filter(el => el.types.includes('postal_code'));
    if(postalCode.length > 0){
      console.log('postalCode', postalCode[0].long_name);
      document.getElementsByName('zipcode')[0].value = postalCode[0].long_name;
      Address['zipcode']=postalCode[0].long_name;
    }
    let addressLine2 = '';
    placeArr.some(el => {
      if (el.types.includes('locality')) {
          //console.log('yes, I entered the if statement')
          return true;
      } else {
        if(el.long_name) {
         console.log(el.long_name);
         addressLine2 += el.long_name+ ', ';
         Address['addressLine2']=addressLine2;
        }
      }
    });
    console.log(addressLine2);
    console.log((document.getElementsByName('addressLine1')[0].value).split(',')[0]);
    document.getElementsByName('addressLine1')[0].value = (document.getElementsByName('addressLine1')[0].value).split(',')[0];
    document.getElementsByName('addressLine2')[0].value = addressLine2;
  
    Address['addressLine1']=(document.getElementsByName('addressLine1')[0].value).split(',')[0];
    Address['placeIdPermanentAddress']=place.place_id;
    console.log(Address, 'adress');
    
    setAddress(Address);
    console.log(address, 'PERMANENT_ADDRESS');
  }

  //documentation
  const[capturedBase64, setCapturedBase64] = useState({});
  const [showPicture, setShowPicture] = useState(false);
  const handleClosePicture = () => setShowPicture(false);
  const handleShowPicture = (classname) => {
    console.log('img',document.getElementsByClassName(classname)[0].src);
    setCapturedBase64(document.getElementsByClassName(classname)[0].src);
    setShowPicture(true);
  }

  const[idmToken, setIdmToken] = useState(null);
  const[startFaceValidation, setStartFaceValidation] = useState(false);
  const[startPPValidation, setStartPPValidation] = useState(false);
  const[startSecDocValidation, setStartSecDocValidation] = useState(false);

  const[errorSelfie, setErrorSelfie] = useState(false);
  const[errorPassport, setErrorPassport] = useState(false);
  const[errorPassportDetails, setErrorPassportDetails] = useState(false);
  const[successPassportDetails, setSuccessPassportDetails] = useState(false);
  const[errorSecDoc, setErrorSecDoc] = useState(false);

  const getFVResponseCallback = (data) => {//Face Validation Response Callback
    console.log(data);
    setInputs({
      ...inputs,
      selfieImg: data.customerData.biometricData.selfie,
      selfieImgTimestamp: new Date()
    });
  }
  
  const handleStartPPValidation = () => {
    setStartFaceValidation(false);
    setStartPPValidation(true);
  }
  
  const getPPVResponseCallback = (data) => {//Passport Validation Response Callback
    console.log(data);
    //alert('getPPVResponseCallback');
    setInputs({
      ...inputs,
      passportImg: data.customerData.idData.idImageFront,
      passportImgTimestamp: new Date(),
    });
    console.log('PPVResponseCallback end');
    //alert('PPVResponseCallback end');
  }
  
  const getSecDocVResponseCallback = (data) => {//Passport Validation Response Callback
    console.log(data);
    //alert('getSecDocVResponseCallback');
    setInputs({
      ...inputs,
      secondaryDocImg: data.customerData.idData.idImageFront,
      secondaryDocImgTimestamp: new Date(),
      secondaryDocBackImg: data.customerData.idData.idImageBack,
      secondaryDocBackImgTimestamp: new Date(),
    });
    console.log('PPVResponseCallback end');
    //alert('PPVResponseCallback end');
  }

  //w8ben
  const handleNextStep = (e) => {
    console.log('nextstep');
    e.preventDefault();
    nextStep();
  }

  //agreements and disclosures
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const handleCloseCancelPopup = () => setShowCancelPopup(false);
  const handleShowCancelPopup = () => setShowCancelPopup(true);

  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const handleCloseSubmitModal = () => {
    setShowSubmitModal(false);
    console.log('Star rating '+document.querySelectorAll('#star-rating .btn-star.on').length);
  }
  
  const handleCheck = () => {
    //setChecked(!checked);
  }

  const [disclosureEntityName, setDisclosureEntityName] = useState(null);
  const [showDisclosure, setShowDisclosure] = useState(false);
  const handleCloseDisclosure = () => setShowDisclosure(false);
  const handleShowDisclosure = (entity_name) => {
    setDisclosureEntityName(entity_name);
    setShowDisclosure(true);
  }

  const [agreeDisabledDisclosure, setAgreeDisabledDisclosure] = useState(true);
  const handleScrollDisclosure = (e) => {
    const bottom = (e.target.scrollHeight * 99 / 100) <= (e.target.clientHeight + e.target.scrollTop) ;
    if (bottom) {
      setAgreeDisabledDisclosure(false);
    }
  }

  const 
  handleDisagreeDisclosure = (entity_name) => {
    console.log(entity_name);
    //setCheckedDisclosure(false);
    document.querySelector(`#${entity_name} input[type="checkbox"]`).checked = false;
    document.querySelector(`#${entity_name} .form-check`).classList.add("disabled");
    setShowDisclosure(false);
  }
  const handleAgreeDisclosure = (entity_name) => {
    console.log(entity_name);
    let targetid = entity_name;
    var formdata = {token:token, entity_group:curPageName, items:{[`${targetid}`]: true}};
    console.log(formdata);
    axios.post(serverConfig.api_base_url + "customer_entity_group_details_set",formdata)
    .then(response=>{
      console.log(response);
      if(response.data.status === 200) {
        console.log('success');
      } else if(response.data.status === 407){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));

    document.querySelector(`#${entity_name} input[type="checkbox"]`).checked = true;
    document.querySelector(`#${entity_name} .form-check`).classList.remove("disabled");
    setShowDisclosure(false);
  }

  const DisclosureContent = (props) => {
    return (
    <>
      <Disclosures />
      <Modal.Footer>
        <Button variant="transparent" onClick={props.onClickDisagree}>Disagree</Button>
        {agreeDisabledDisclosure ?
          <Button variant="primary" disabled>Agree</Button> : <Button variant="primary" onClick={props.onClickAgree}>Agree</Button>
        }
      </Modal.Footer>
    </>
    );
  }

  const ConsentCommunicationsContent = (props) => {
    return (
    <>
      <Disclosures />
      <Modal.Footer>
        <Button variant="transparent" onClick={props.onClickDisagree}>Disagree</Button>
        {agreeDisabledDisclosure ?
          <Button variant="primary" disabled>Agree</Button> : <Button variant="primary" onClick={props.onClickAgree}>Agree</Button>
        }
      </Modal.Footer>
    </>
    );
  }

  let disclosureModalContent;
  if (disclosureEntityName === 'electronic_communication') {
     disclosureModalContent = <><ConsentCommunicationsContent onClickDisagree={() => handleDisagreeDisclosure('electronic_communication')} onClickAgree={() => handleAgreeDisclosure('electronic_communication')}/></>;
  } else if (disclosureEntityName === 'disclosure') {
     disclosureModalContent = <><DisclosureContent onClickDisagree={() => handleDisagreeDisclosure('disclosure')} onClickAgree={() => handleAgreeDisclosure('disclosure')} /></>;
  } else if (disclosureEntityName === 'fund_polcy') {
    disclosureModalContent = <><DisclosureContent onClickDisagree={() => handleDisagreeDisclosure('fund_polcy')} onClickAgree={() => handleAgreeDisclosure('fund_polcy')} /></>;
  } else if (disclosureEntityName === 'privacy_notice') {
    disclosureModalContent = <><DisclosureContent onClickDisagree={() => handleDisagreeDisclosure('privacy_notice')} onClickAgree={() => handleAgreeDisclosure('privacy_notice')} /></>;
  } else if (disclosureEntityName === 'savings_act') {
    disclosureModalContent = <><DisclosureContent onClickDisagree={() => handleDisagreeDisclosure('savings_act')} onClickAgree={() => handleAgreeDisclosure('savings_act')} /></>;
  }

  return (
    <Container fluid>
      <div id={curPageName}>
        {isLoading && (<div id='spinner'></div>)}

        {errorSelfie && <div id='loading'><div className='loading-wrap bg-white'><img src={require('./../assets/images/error-icon.png')} alt="Error" /><div>Error capturing selfie. Please retry<br/><button className='btn btn-primary' onClick={() => setErrorSelfie(false)}>Close</button></div></div></div>}

        {errorPassport && <div id='loading'><div className='loading-wrap bg-white'><img src={require('./../assets/images/error-icon.png')} alt="Error" /><div>Error capturing Passport. Please retry<br/><button className='btn btn-primary' onClick={() => setErrorPassport(false)}>Close</button></div></div></div>}

        {errorPassportDetails && <div id='loading'><div className='loading-wrap bg-white'><img src={require('./../assets/images/error-icon.png')} alt="Error" /><div>Couldn't read passport details. Please try again<br/><button className='btn btn-primary' onClick={() => setErrorPassportDetails(false)}>Close</button></div></div></div>}

        {successPassportDetails && <div id='loading'>
          <div className='loading-wrap bg-white'>
            <div className="mb-2"><b>Passport details</b></div>
            <div>
              <div className="d-flex justify-content-between">ID Number: <b>{inputs.passportNumber}</b></div>
              <div className="d-flex justify-content-between">Country Code: <b>{inputs.countryOfIssuance}</b></div>
              <div className="d-flex justify-content-between">Issue Date: <b>{inputs.IssuanceDate}</b></div>
              <div className="d-flex justify-content-between">Expiration Date: <b>{inputs.expirationDate}</b></div>
              <button className='btn btn-primary' onClick={() => setSuccessPassportDetails(false)}>Close</button>
            </div>
          </div>
        </div>}

        {errorSecDoc && <div id='loading'><div className='loading-wrap bg-white'><img src={require('./../assets/images/error-icon.png')} alt="Error" /><div>Error capturing Secondary Document. Please retry<br/><button className='btn btn-primary' onClick={() => setErrorSecDoc(false)}>Close</button></div></div></div>}
        
        <Form onSubmit={submitFormData}>
          <Container fluid>
            <fieldset /* disabled={isReadOnly} */>
              {(curPageName === 'documentation' || curPageName === 'w8ben_instruction1') && <input type="hidden" id="token" name="token" value={token} />}
              {groupData.group_header && <div className="mb-3" dangerouslySetInnerHTML={{__html: groupData.group_header}}></div>}

              <div>
                {sectionData.length !== 0 &&
                  sectionData.map((sectionItem, sectionIndex) => {
                    var divClass;
                    if(sectionItem.section_name === "mailing_address"){
                      if(showMailingAddr){
                        divClass = 'd-block';
                      } else {
                        divClass = 'd-none';
                      }
                    } else if(sectionItem.section_name === "w8ben_part1_mailing_address"){
                      if(showMailingAddr){
                        divClass = 'd-block';
                      } else {
                        divClass = 'd-none';
                      }
                    } else if(sectionItem.section_name === "alternate_us_address"){
                      if(showAltUSAddr){
                        divClass = 'd-block';
                      } else {
                        divClass = 'd-none';
                      }
                    } else if(sectionItem.section_name === "passport_fields"){
                      divClass = 'pp-fields';
                    } else if(sectionItem.section_name === "w8ben_instruction1_company_info"){
                      if(companyInfo){
                        divClass = 'border pt-3 px-3 mb-3 d-block';
                      } else {
                        divClass = 'd-none';
                      }
                    } else if(sectionItem.section_name === "passport_details"){
                      divClass = 'capture-selfie-pp';
                    } else if(sectionItem.section_name === "secondary_document_details"){
                      divClass = 'capture-sec-doc';
                    }
                    
                    if(sectionItem.section_type === "mobile_number"){
                      divClass = 'phone-number-wrap d-flex';
                    } else if(sectionItem.section_type === "number_amount"){
                      divClass = 'number-amount';
                    }

                    return(<>
                      {!showQrCode && <section key={sectionIndex}>
                        {sectionItem.section_title && <h4 className="mb-3" dangerouslySetInnerHTML={{__html: sectionItem.section_title}} />}
                        {sectionItem.section_header && <div className="mb-3" dangerouslySetInnerHTML={{__html: sectionItem.section_header}}></div>}

                        {sectionItem.section_name === "mailing_address" &&
                          <Form.Group className="mb-3" controlId="diffMailAddr">
                            <Form.Check type="checkbox" label="Is different from above" onChange={handleDiffMailAddr} checked={mailingAddrChecked} />
                          </Form.Group>
                        }

                        {sectionItem.section_name === "w8ben_part1_mailing_address" &&
                          <Form.Group className="mb-3" controlId="diffMailAddr">
                            <Form.Check type="checkbox" label="Is different from above" checked={mailingAddrChecked} readOnly />
                          </Form.Group>
                        }

                        {/* {sectionItem.section_name === "alternate_us_address" &&
                          <Form.Group className="mb-3" controlId="altUSAddr">
                            <Form.Check type="checkbox" label="I have an alternate US address" onChange={handleAltUSAddr} checked={altUSAddrChecked} />
                          </Form.Group>
                        } */}
                        
                        {sectionItem.section_entities &&
                          <div className={divClass}>
                            {sectionItem.section_entities.sort(function(a,b){//sort as per sequence no. and then map
                              return a.entity_seq_no - b.entity_seq_no;
                            }).map((entityItem, entityIndex) => {
                              return(<>
                              {(sectionItem.section_type === "number_amount" && entityItem.entity_label) &&
                                <Form.Label>
                                  <span dangerouslySetInnerHTML={{__html: entityItem.entity_label}} /> {entityItem.entity_mandatory === 1 && <span className='txt-red'>*</span>}
                                  {entityItem.entity_info &&
                                    <OverlayTrigger placement="top" overlay={<Tooltip><span dangerouslySetInnerHTML={{__html: entityItem.entity_info}} /></Tooltip>}>
                                      <span className="info"><i>i</i></span>
                                    </OverlayTrigger>
                                  }
                                </Form.Label>
                                }
                                <Form.Group key={entityIndex} className={(entityItem.entity_name === 'otherSources' || entityItem.entity_name === 'otherSourceOfInitialDeposit') ? (otherSources ? "mb-3 d-block" : "mb-3 d-none") : 'mb-3'}>
                                {//type = text
                                  (entityItem.entity_data_type.type === 'text' && entityItem.entity_name !== 'submitted') && <>
                                    {entityItem.entity_label && <>
                                      <Form.Label>
                                        <span dangerouslySetInnerHTML={{__html: entityItem.entity_label}} /> {(entityItem.entity_mandatory === 1 || (sectionItem.section_name === "mailing_address" && !entityItem.entity_name.includes("addressLine2")) || (sectionItem.section_name === "w8ben_part1_mailing_address" && !entityItem.entity_name.includes("addressLine2"))) && <span className='txt-red'>*</span>}
                                        {entityItem.entity_info &&
                                          <OverlayTrigger placement="top" overlay={<Tooltip><span dangerouslySetInnerHTML={{__html: entityItem.entity_info}} /></Tooltip>}>
                                            <span className="info"><i>i</i></span>
                                          </OverlayTrigger>
                                        }
                                      </Form.Label>
                                    </>}
                                    {entityItem.entity_name === "w8ben_treaty" ? <div className="w-75" style={{display: "flex"}}>
                                    <Form.Control name={entityItem.entity_name} defaultValue={(inputs[entityItem.entity_name] && inputs[entityItem.entity_name].replace(/&apos;/g,"'")) || (entityItem.entity_data && entityItem.entity_data.replace(/&apos;/g,"'"))} onChange={handleChange} className={entityItem.entity_data_type.is_editable === 0 ? 'readonly' : undefined} onKeyDown={(e) => handleDelete(entityItem.entity_data_type.is_editable === 0 ? false : true, e)} type="text" disabled={(sectionItem.section_name === "mailing_address" && isMailAddrDisabled)} required={entityItem.entity_mandatory === 1 || (sectionItem.section_name === "mailing_address" && isMailAddrRequired)} 
                                    readOnly={entityItem.entity_data_type.is_readonly === 1}
                                    /> <p style={{marginLeft : "40px", marginTop:"5px"}}>%</p>
                                    </div> :
                                    <Form.Control name={entityItem.entity_name} defaultValue={(inputs[entityItem.entity_name] && inputs[entityItem.entity_name].replace(/&apos;/g,"'")) || (entityItem.entity_data && entityItem.entity_data.replace(/&apos;/g,"'"))} onChange={handleChange} className={entityItem.entity_data_type.is_editable === 0 ? 'readonly' : undefined} onKeyDown={(e) => handleDelete(entityItem.entity_data_type.is_editable === 0 ? false : true, e)} type="text" disabled={((sectionItem.section_name === "mailing_address" && !entityItem.entity_name.includes("addressLine2")) && isMailAddrDisabled)} required={entityItem.entity_mandatory === 1 || ((sectionItem.section_name === "mailing_address" && !entityItem.entity_name.includes("addressLine2")) && isMailAddrRequired)} readOnly={entityItem.entity_data_type.is_readonly === 1} minLength={entityItem.entity_data_type.min_characters ? entityItem.entity_data_type.min_characters : undefined} />}
                                  </>
                                }

                                {//type = location
                                entityItem.entity_data_type.type === 'location' && <>
                                  {entityItem.entity_label && <>
                                    <Form.Label>
                                      <span dangerouslySetInnerHTML={{__html: entityItem.entity_label}} /> {entityItem.entity_mandatory === 1 && <span className='txt-red'>*</span>}
                                      {entityItem.entity_info &&
                                        <OverlayTrigger placement="top" overlay={<Tooltip><span dangerouslySetInnerHTML={{__html: entityItem.entity_info}} /></Tooltip>}>
                                          <span className="info"><i>i</i></span>
                                        </OverlayTrigger>
                                      }
                                    </Form.Label>
                                  </>}
                                  {/* <Autocomplete apiKey="AIzaSyBafPK5TmCxMIAXCroevkGly1srtn6WB8s"           
                                    /> */}
                                </>}

                                {//type = displayText
                                  (entityItem.entity_data_type.type === 'displayText' && entityItem.entity_name !== 'submitted') && <>
                                    {entityItem.entity_label && <>
                                      <Form.Label>
                                        <span dangerouslySetInnerHTML={{__html: entityItem.entity_label}} /> {entityItem.entity_mandatory === 1 && <span className='txt-red'>*</span>}
                                        {entityItem.entity_info &&
                                          <OverlayTrigger placement="top" overlay={<Tooltip><span dangerouslySetInnerHTML={{__html: entityItem.entity_info}} /></Tooltip>}>
                                            <span className="info"><i>i</i></span>
                                          </OverlayTrigger>
                                        }
                                      </Form.Label>
                                    </>}
                                  </>
                                }
                                {//type = longtext
                                  entityItem.entity_data_type.type === 'longtext' && <>
                                    {entityItem.entity_label &&
                                      <Form.Label>
                                        <span dangerouslySetInnerHTML={{__html: entityItem.entity_label}} /> {entityItem.entity_mandatory === 1 && <span className='txt-red'>*</span>}
                                        {entityItem.entity_info &&
                                          <OverlayTrigger placement="top" overlay={<Tooltip><span dangerouslySetInnerHTML={{__html: entityItem.entity_info}} /></Tooltip>}>
                                            <span className="info"><i>i</i></span>
                                          </OverlayTrigger>
                                        }
                                      </Form.Label>
                                    }

                                    <Form.Control as="textarea" id={entityItem.entity_name} readOnly={entityItem.entity_data_type.is_readonly === 1} name={entityItem.entity_name} defaultValue={(inputs[entityItem.entity_name] && inputs[entityItem.entity_name].replace(/<br\s*\/?>/gi,'\n').replace(/&apos;/g,"'")) || (entityItem.entity_data && entityItem.entity_data.replace(/<br\s*\/?>/gi,'\n').replace(/&apos;/g,"'"))} placeholder={'Type here...'} onChange={handleChange} className={entityItem.entity_data_type.is_editable === 0 ? 'readonly' : undefined} onKeyDown={(e) => handleDelete(entityItem.entity_data_type.is_editable === 0 ? false : true, e)} style={{ height: '100px' }} required={entityItem.entity_mandatory === 1} />
                                  </>
                                }
                                {//type = phone_number
                                  entityItem.entity_data_type.type === 'phone_number' && <>
                                    {entityItem.entity_label && <>
                                      <Form.Label>
                                        <span dangerouslySetInnerHTML={{__html: entityItem.entity_label}} /> {entityItem.entity_mandatory === 1 && <span className='txt-red'>*</span>}
                                        {entityItem.entity_info &&
                                          <OverlayTrigger placement="top" overlay={<Tooltip><span dangerouslySetInnerHTML={{__html: entityItem.entity_info}} /></Tooltip>}>
                                            <span className="info"><i>i</i></span>
                                          </OverlayTrigger>
                                        }
                                      </Form.Label>
                                    </>}
                                    <Form.Control name={entityItem.entity_name} readOnly={entityItem.entity_data_type.is_readonly === 1} defaultValue={inputs[entityItem.entity_name] || entityItem.entity_data} onChange={handleChange} className={entityItem.entity_data_type.is_editable === 0 ? 'readonly' : undefined} onKeyDown={(e) => handleDelete(entityItem.entity_data_type.is_editable === 0 ? false : true, e)} type="tel" placeholder="1234567890" pattern="^[0-9]*$" required={entityItem.entity_mandatory === 1} />
                                  </>
                                }
                                {//type = amount
                                  entityItem.entity_data_type.type === 'amount' && <>
                                    {entityItem.entity_label && <>
                                      <Form.Label>
                                        <span dangerouslySetInnerHTML={{__html: entityItem.entity_label}} /> {entityItem.entity_mandatory === 1 && <span className='txt-red'>*</span>}
                                        {entityItem.entity_info &&
                                          <OverlayTrigger placement="top" overlay={<Tooltip><span dangerouslySetInnerHTML={{__html: entityItem.entity_info}} /></Tooltip>}>
                                            <span className="info"><i>i</i></span>
                                          </OverlayTrigger>
                                        }
                                      </Form.Label>
                                    </>}
                                    <Form.Control name={entityItem.entity_name} readOnly={entityItem.entity_data_type.is_readonly === 1} defaultValue={inputs[entityItem.entity_name] || entityItem.entity_data} onChange={handleChange} className={entityItem.entity_data_type.is_editable === 0 ? 'readonly' : undefined} onKeyDown={(e) => handleDelete(entityItem.entity_data_type.is_editable === 0 ? false : true, e)} type="number" min="0" placeholder="USD" required={entityItem.entity_mandatory === 1} />
                                  </>
                                }
                                {//type = email
                                  entityItem.entity_data_type.type === 'email' && <>
                                    {entityItem.entity_label && <>
                                      <Form.Label>
                                        <span dangerouslySetInnerHTML={{__html: entityItem.entity_label}} /> {entityItem.entity_mandatory === 1 && <span className='txt-red'>*</span>}
                                        {entityItem.entity_info &&
                                          <OverlayTrigger placement="top" overlay={<Tooltip><span dangerouslySetInnerHTML={{__html: entityItem.entity_info}} /></Tooltip>}>
                                            <span className="info"><i>i</i></span>
                                          </OverlayTrigger>
                                        }
                                      </Form.Label>
                                    </>}
                                    <Form.Control name={entityItem.entity_name} readOnly={entityItem.entity_data_type.is_readonly === 1} defaultValue={inputs[entityItem.entity_name] || entityItem.entity_data} onChange={handleChange} className={entityItem.entity_data_type.is_editable === 0 ? 'readonly' : undefined} onKeyDown={(e) => handleDelete(entityItem.entity_data_type.is_editable === 0 ? false : true, e)} type="email" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-z]{2,}$" required={entityItem.entity_mandatory === 1} />
                                  </>
                                }
                                {//type = url
                                  entityItem.entity_data_type.type === 'url' && <>
                                    {entityItem.entity_label && <>
                                      <Form.Label>
                                        <span dangerouslySetInnerHTML={{__html: entityItem.entity_label}} /> {entityItem.entity_mandatory === 1 && <span className='txt-red'>*</span>}
                                        {entityItem.entity_info &&
                                          <OverlayTrigger placement="top" overlay={<Tooltip><span dangerouslySetInnerHTML={{__html: entityItem.entity_info}} /></Tooltip>}>
                                            <span className="info"><i>i</i></span>
                                          </OverlayTrigger>
                                        }
                                      </Form.Label>
                                    </>}
                                    <Form.Control name={entityItem.entity_name} readOnly={entityItem.entity_data_type.is_readonly === 1} defaultValue={inputs[entityItem.entity_name] || entityItem.entity_data} onChange={handleChange} className={entityItem.entity_data_type.is_editable === 0 ? 'readonly' : undefined} onKeyDown={(e) => handleDelete(entityItem.entity_data_type.is_editable === 0 ? false : true, e)} type="text" pattern="(https?:\/\/|)([A-Za-z0-9-]+\.)+[A-Za-z]{2,20}" required={entityItem.entity_mandatory === 1} />
                                  </>
                                }
                                {//type = radio
                                  entityItem.entity_data_type.type === 'radio' && <>
                                    {entityItem.entity_label && <>
                                      <Form.Label>
                                        <span dangerouslySetInnerHTML={{__html: entityItem.entity_label}} /> {entityItem.entity_mandatory === 1 && <span className='txt-red'>*</span>}
                                        {entityItem.entity_info &&
                                          <OverlayTrigger placement="top" overlay={<Tooltip><span dangerouslySetInnerHTML={{__html: entityItem.entity_info}} /></Tooltip>}>
                                            <span className="info"><i>i</i></span>
                                          </OverlayTrigger>
                                        }
                                      </Form.Label>
                                    </>}
                                    <div className="d-flex flex-wrap">
                                      {entityItem.entity_data_type.options.map((options, optionsIndex) =>
                                        <Form.Check key={optionsIndex} inline type="radio" name={entityItem.entity_name} value={options || ""} onChange={handleChange} id={entityItem.entity_name + options} label={options} defaultChecked={entityItem.entity_data === options} required={entityItem.entity_mandatory === 1} />
                                      )}
                                    </div>
                                  </>
                                }
                                {//type = lookup
                                  entityItem.entity_data_type.type === 'lookup' && <>
                                    {entityItem.entity_label && <>
                                      <Form.Label className="mr-3">
                                        <span dangerouslySetInnerHTML={{__html: entityItem.entity_label}} /> {(entityItem.entity_mandatory === 1 || sectionItem.section_name === "mailing_address" || sectionItem.section_name === "alternate_us_address" || sectionItem.section_name === "w8ben_instruction1_company_info") && <span className='txt-red'>*</span>}
                                        {entityItem.entity_info &&
                                          <OverlayTrigger placement="top" overlay={<Tooltip><span dangerouslySetInnerHTML={{__html: entityItem.entity_info}} /></Tooltip>}>
                                            <span className="info"><i>i</i></span>
                                          </OverlayTrigger>
                                        }
                                      </Form.Label>
                                    </>}

                                    {entityItem.entity_name === 'countryOfIssuance' && <input type="hidden" name="countryCode" id="countryCode" />}

                                    {entityItem.entity_data_type.key === 'countryCode' &&
                                      <Form.Select readOnly={entityItem.entity_data_type.is_readonly === 1} name={entityItem.entity_name} defaultValue={entityItem.entity_data || ""} onChange={handleChange} id={entityItem.entity_name} required={entityItem.entity_mandatory === 1}>
                                        <option className="show-value" value="" style={{display:"none"}}></option>
                                        <option></option>
                                        {countryLookup.map((item, index) => <option key={index} value={item.phonecode}>{item.value} ({item.phonecode})</option>)}
                                      </Form.Select>
                                    }

                                    {entityItem.entity_data_type.is_searchable === 1 && <>
                                      <Form.Control name={entityItem.entity_name} defaultValue={inputs[entityItem.entity_name] || entityItem.entity_data || ""} onBlur={(entityItem.entity_data_type.key === 'country' || entityItem.entity_data_type.key === 'citizenship') ? handleOptions : undefined} onChange={handleChange} type="text" list={entityItem.entity_name} disabled={(sectionItem.section_name === "mailing_address" && isMailAddrDisabled) || (sectionItem.section_name === "alternate_us_address" && isAltUSAddrDisabled) || (sectionItem.section_name === "w8ben_instruction1_company_info" && isCompanyInfoDisabled)} required={entityItem.entity_mandatory === 1 || (sectionItem.section_name === "mailing_address" && isMailAddrRequired) || (sectionItem.section_name === "alternate_us_address" && isAltUSAddrRequired) || (sectionItem.section_name === "w8ben_instruction1_company_info" && isCompanyInfoRequired)} className={entityItem.entity_data_type.is_editable === 0 ? 'readonly' : undefined} onKeyDown={entityItem.entity_data_type.is_editable === 0 ? (e)=>{e.preventDefault()} : undefined} />

                                      <datalist id={entityItem.entity_name}>
                                        <option></option>
                                        {(() => {
                                          if(entityItem.entity_data_type.key === 'country'){
                                            return (<>
                                              {countryLookup &&
                                                countryLookup.map((item, index) => <option value={item.value} key={index} data-value={item.country_code}>{item.value}</option>)
                                              }
                                            </>)
                                          } else if(entityItem.entity_data_type.key === 'citizenship'){
                                            return (<>
                                              {countryLookup &&
                                                countryLookup.map((item, index) => <option value={item.citizenship} key={index} data-value={item.country_code}>{item.citizenship}</option>)
                                              }
                                            </>)
                                          } else if(entityItem.entity_data_type.key === 'state'){
                                            if(entityItem.entity_name === 'stateMailingAddress'){
                                              return(<>{stateMALookup &&
                                                stateMALookup.map((item, index) => <option value={item.value} key={index}>{item.value}</option>)
                                              }</>)
                                            } else if(entityItem.entity_name === 'stateUS'){
                                              return(<>{stateUSLookup &&
                                                stateUSLookup.map((item, index) => <option value={item.value} key={index}>{item.value}</option>)
                                              }</>)
                                            } else {
                                              return (<>{stateLookup &&
                                                stateLookup.map((item, index) => <option value={item.value} key={index}>{item.value}</option>)
                                              }</>)
                                            }
                                          } else if(entityItem.entity_data_type.key === 'city'){
                                            if(entityItem.entity_name === 'cityMailingAddress'){
                                              return(<>{cityMALookup &&
                                                cityMALookup.map((item, index) => <option value={item.value} key={index}>{item.value}</option>)
                                              }</>)
                                            } else if(entityItem.entity_name === 'cityUS'){
                                              return (<>{cityUSLookup &&
                                                cityUSLookup.map((item, index) => <option value={item.value} key={index}>{item.value}</option>)
                                              }</>)
                                            } else {
                                              return (<>{cityLookup &&
                                                cityLookup.map((item, index) => <option value={item.value} key={index}>{item.value}</option>)
                                              }</>)
                                            }
                                          }
                                          return null;
                                        })()}
                                      </datalist>
                                    </>}

                                    {entityItem.entity_data_type.options &&
                                      <Form.Select readOnly={entityItem.entity_data_type.is_readonly === 1} name={entityItem.entity_name} defaultValue={entityItem.entity_data || ""} onChange={handleChange} id={entityItem.entity_name} required={entityItem.entity_mandatory === 1}>
                                        <option value="">Select</option>
                                        {entityItem.entity_data_type.options.map((options, optionsIndex) =>
                                          <option key={optionsIndex} value={options}>{options}</option>
                                        )}
                                      </Form.Select>
                                    }
                                  </>
                                }
                                {//type = date
                                  entityItem.entity_data_type.type === 'date' && <>
                                    {entityItem.entity_label && <>
                                      <Form.Label className="mr-3">
                                        <span dangerouslySetInnerHTML={{__html: entityItem.entity_label}} /> {entityItem.entity_mandatory === 1 && <span className='txt-red'>*</span>}
                                        {entityItem.entity_info &&
                                          <OverlayTrigger placement="top" overlay={<Tooltip><span dangerouslySetInnerHTML={{__html: entityItem.entity_info}} /></Tooltip>}>
                                            <span className="info"><i>i</i></span>
                                          </OverlayTrigger>
                                        }
                                      </Form.Label>
                                    </>}
                                    <Form.Control name={entityItem.entity_name} defaultValue={inputs[entityItem.entity_name] || entityItem.entity_data} onChange={handleChange} className={entityItem.entity_data_type.is_editable === 0 ? 'readonly' : undefined} onKeyDown={(e) => handleDelete(entityItem.entity_data_type.is_editable === 0 ? false : true, e)} type="date" required={entityItem.entity_mandatory === 1} min={entityItem.entity_name === 'expirationDate' ? new Date(new Date().getTime() + 24*60*60*1000).toISOString().split('T')[0] : undefined} max={(entityItem.entity_name === 'dateOfBirth' ? max18years : undefined) || (entityItem.entity_name === 'IssuanceDate' ? new Date(new Date().getTime() - 24*60*60*1000).toISOString().split('T')[0] : undefined)} readOnly={entityItem.entity_data_type.is_readonly === 1} />
                                      {!entityItem.entity_label && entityItem.entity_mandatory === 1 && <span className='txt-red'>*</span>}
                                  </>
                                }
                                {//type = multiselect
                                  entityItem.entity_data_type.type === 'multiselect' && <>
                                    {entityItem.entity_label && <>
                                      <Form.Label><h5>
                                        <span dangerouslySetInnerHTML={{__html: entityItem.entity_label}} /> {entityItem.entity_mandatory === 1 && <span className='txt-red'>*</span>}
                                        {entityItem.entity_info &&
                                          <OverlayTrigger placement="top" overlay={<Tooltip><span dangerouslySetInnerHTML={{__html: entityItem.entity_info}} /></Tooltip>}>
                                            <span className="info"><i>i</i></span>
                                          </OverlayTrigger>
                                        }
                                      </h5></Form.Label>
                                    </>}
                                    {entityItem.entity_data_type.options && entityItem.entity_data_type.options.map((options, optionsIndex) =>
                                      <Form.Check key={optionsIndex} type="checkbox" name={entityItem.entity_name} value={options} onChange={handleChange} id={options} label={options} defaultChecked={entityItem.entity_data && entityItem.entity_data.includes(options)} required={entityItem.entity_mandatory === 1} />
                                    )}
                                  </>
                                }
                                {//type = boolean
                                  entityItem.entity_data_type.type === 'boolean' && <>
                                    <div id={entityItem.entity_name}><Form.Check type="checkbox" className="disabled" onClick={handleCheck} defaultChecked={entityItem.entity_data} label={<><span onClick={() => handleShowDisclosure(entityItem.entity_name)} dangerouslySetInnerHTML={{__html: entityItem.entity_label}} /> {entityItem.entity_mandatory === 1 && <span className='txt-red'>*</span>}</>} required={entityItem.entity_mandatory === 1} /></div>

                                    <Modal show={showDisclosure} onHide={handleCloseDisclosure} centered backdrop="static"keyboard={false} className="modal-ecdc">
                                      <Modal.Body>
                                        <div onScroll={handleScrollDisclosure}>
                                          {/* <div className='text-center mb-3'><img src={require('./../assets/images/logo.png')} alt={window.bankName} className='img-fluid' /></div> */}
                                          {/* <h1>Terms and Conditions</h1>
                                          <h3>Electronic Communication Disclosure and Consent</h3> */}
                                          {disclosureModalContent}
                                        </div>
                                      </Modal.Body>
                                    </Modal>
                                  </>
                                }
                                {//type = json
                                  

                                  sectionItem.section_name === 'passport_details' && <>
                                  
                                      {entityItem.entity_data_type.type === 'json' && <>
                                      <Form.Label>Click Picture {entityItem.entity_mandatory === 1 && <span className='txt-red'>*</span>}</Form.Label>
                                      <Button variant="click" type="button" id="captureSelfiePassport" onClick={() => setStartFaceValidation(true)}>
                                        <span>
                                          <img alt="Camera" src={require('./../assets/images/camera-icon.png')} className="me-2" />
                                          <span dangerouslySetInnerHTML={{__html: entityItem.entity_label}} />
                                        </span>
                                      </Button>
                                      </>}
                                      {entityItem.entity_data_type.type === 'image' && <>
                                        {entityItem.entity_data_type.idType === 'Selfie' &&
                                        <span className={(inputs[entityItem.entity_name] || entityItem.entity_data) ? 'view d-block' : 'view d-none'} onClick={() => handleShowPicture('selfieImg')}>{(inputs[entityItem.entity_name] || entityItem.entity_data) ? (<img alt="Selfie" src={'data:image/jpeg;base64,'+(inputs[entityItem.entity_name] || entityItem.entity_data)} className="image selfieImg" height="70" width="50"/>) : (<img alt="Selfie" src="" className="image selfieImg" height="70" width="50"/>)}<span>View Picture</span></span>}
                                        {entityItem.entity_data_type.idType === 'Passport' &&
                                        <span className={(inputs[entityItem.entity_name] || entityItem.entity_data) ? 'view d-block' : 'view d-none'} onClick={() => handleShowPicture('passportImg')}>{(inputs[entityItem.entity_name] || entityItem.entity_data) ? (<img alt="Passport" src={'data:image/jpeg;base64,'+(inputs[entityItem.entity_name] || entityItem.entity_data)} className="image passportImg" height="70" width="50"/>) : (<img alt="Passport" src="" className="image passportImg" height="70" width="50"/>)}<span>View Doc</span></span>}
                                      </>}
                                  </>
                                }

                                {sectionItem.section_name === "secondary_document_details" && <>
                                                                      
                                    {entityItem.entity_data_type.type === 'json' && <>
                                    <Form.Label>Click Picture {entityItem.entity_mandatory === 1 && <span className='txt-red'>*</span>}</Form.Label>
                                    <Button variant="click" type="button" id="secondaryDocImg" onClick={() => setStartSecDocValidation(true)}>
                                      <span>
                                        <img alt="Camera" src={require('./../assets/images/camera-icon.png')} className="me-2" />
                                        <span dangerouslySetInnerHTML={{__html: entityItem.entity_label}} />
                                      </span>
                                    </Button>
                                    </>}
                                    {entityItem.entity_data_type.type === 'image' && <>
                                      {entityItem.entity_data_type.idType === 'SecondaryDoc' &&
                                      <span className={(inputs[entityItem.entity_name] || entityItem.entity_data) ? 'view d-block' : 'view d-none'} onClick={() => handleShowPicture(entityItem.entity_name)}>{(inputs[entityItem.entity_name] || entityItem.entity_data) ? (<img alt="" src={'data:image/jpeg;base64,'+(inputs[entityItem.entity_name] || entityItem.entity_data)} className={"image "+ entityItem.entity_name} height="70" width="50"/>) : (<img alt="" src="" className={"image "+ entityItem.entity_name} height="70" width="50"/>)}<span>View Doc</span></span>}
                                      
                                    </>}
                                  </>
                                }

                                {//type = image
                                      <Modal show={showPicture} onHide={handleClosePicture} className='view-picture' centered>
                                        <Modal.Header closeButton></Modal.Header>
                                        <Modal.Body><img src={capturedBase64} width="100%" alt="Close" /></Modal.Body>
                                      </Modal>
                                }
                                </Form.Group>
                              </>)
                            })}
                          </div>
                        }

                        {sectionItem.section_footer && <div dangerouslySetInnerHTML={{__html: sectionItem.section_footer}} />}
                      </section>}
                    </>);
                  })
                }
              </div>

              {groupData.group_footer && <div dangerouslySetInnerHTML={{__html: groupData.group_footer}} />}

              {showQrCode && <div className="text-center">
                <div className="qrcode-image"><QRCode value={window.location.href} size="200" /></div>
                <p>To continue with the ID verification you would need to scan the above QR code and please keep your passport and secondary document handy</p>
              </div>}
            </fieldset>
          </Container>
          <Container fluid className='btn-container'>
            {/* {stepNum}-{stepLength} */}
            <Row>
              <Col>
                {stepNum === 1 ? <Button variant="primary" disabled={prevDisabled ? true : false} onClick={() => navigate("/db")}>Close</Button> : <Button variant="primary" disabled={prevDisabled ? true : false} onClick={(e) => {setPrevDisabled(true); prevStep();}}>Previous</Button>}
              </Col>
              {stepNum === stepLength &&
                <Col xs={4}>
                  <Button variant="transparent" onClick={handleShowCancelPopup}>Cancel</Button>
                  <Modal show={showCancelPopup} onHide={handleCloseCancelPopup} backdrop="static" keyboard={false} centered>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>Are you sure you are about to exit this application?</Modal.Body>
                    <Modal.Footer>
                      <Button variant="transparent" onClick={() => navigate("/db")}>Yes</Button>
                      <Button variant="primary" onClick={handleCloseCancelPopup}>No</Button>
                    </Modal.Footer>
                  </Modal>
                </Col>
              }
              <Col>
                {curPageName === 'w8ben' ?
                  <Button variant="primary" type="button" name="verify_otp" disabled={nextDisabled ? true : false} onClick={handleNextStep}>Next</Button>
                  :
                  <Button variant="primary" disabled={nextDisabled ? true : false} type="submit">{stepNum === stepLength ? "Submit" : "Next"}</Button>
                }
              </Col>
            </Row>
          </Container>
        </Form>

        {startFaceValidation && <div className="validation-popup">
          <FaceValidation 
            submissionToken={idmToken} 
            //onBeforeSubmit={(data)=>getFVResponseCallback(data)}
            onExitCapture={() => setStartFaceValidation(false)}
            /* onApproved={(submissionResponse) => {
              console.log('Your Selfie has been validated, Retake or Proceed to PP validation', submissionResponse);
            }} */
            onDenied={() => {setErrorSelfie(true);}}
            onComplete={(submissionResponse, submissionRequest) => {
              console.log('Your Selfie has been validated, proceeding to PP validation.', submissionResponse);
              console.log('submissionRequest', submissionRequest);
              setInputs({
                ...inputs,
                selfieImg: submissionRequest.customerData.biometricData.selfie,
                selfieImgTimestamp: new Date()
              });
              handleStartPPValidation();
            }} 
            colors={
              {
                loadingOverlay:{
                  continueBtn: {
                    backgroundColor:'#FDC600',
                    textColor: '#000000'
                  }
                }
              }
            }
            geolocationEnabled={false}
            geolocationRequired={false}
          />
        </div>}

        {startPPValidation && <div className="validation-popup">
          <IdValidation 
            submissionToken={idmToken} 
            //onBeforeSubmit={(data)=>getPPVResponseCallback(data)}
            //idAutoCaptureEnabled={false}
            idCaptureRequirement="passport"
            //idCardAutoCaptureScoreThreshold="0.01"
            //idCardFocusScoreThreshold="0.01"
            //passportAutoCaptureScoreThreshold="0.01"
            //passportFocusScoreThreshold="0.01"
            idBackImageRequired={false}
            onExitCapture={() => {setStartFaceValidation(false); setStartPPValidation(false)}}
            //onDenied={() => {setErrorPassport(true);}} 
            onComplete={(submissionResponse, submissionRequest) => {
              console.log('inputs', inputs);
              console.log('Your PP has been validated', submissionResponse);
              console.log('submissionRequest', submissionRequest);
              //alert('Your PP has been validated, proceeding to PP response evaluation.');

              if ('FAIL' === submissionResponse.status || submissionResponse.status.statusCode != "000") {
                console.log("Error capturing Passport and Selfie. Please retry");
                setErrorPassport(true);
                setStartPPValidation(false);
              } else {
                console.log('Passport and Selfie captured successfully.');
                let extractedData = submissionResponse.responseCustomerData.extractedIdData;

                var us_citizen, pep, watchlist, negative_media, countryOfIssuance;
                var inputVal={};
                //var msg = '';

                if (extractedData.idNumber != null && extractedData.idIssueDate != null && extractedData.idIssueCountry != null && extractedData.idExpirationDate != null) {
                  //alert(extractedData.idNumber, extractedData.idIssueDate, extractedData.idIssueCountry, extractedData.idExpirationDate);
                  console.log('PP',extractedData.idNumber);
                  console.log('idIssueDate',extractedData.idIssueDate);
                  console.log('idIssueCountry',extractedData.idIssueCountry);
                  console.log('idExpirationDate',extractedData.idExpirationDate);
                  
                  let options = document.querySelectorAll('#countryOfIssuance option');
                  for(var i = 0; i < options.length; i++) {
                    var option = options[i];
                    if(option.getAttribute('data-value') === extractedData.idIssueCountry) {
                      console.log(option.getAttribute('data-value'));
                      console.log(option.innerText);
                      //$('input[name="countryOfIssuance"]').val(option.innerText); // assign country name
                      countryOfIssuance=option.innerText;
                      break;
                    }
                  }

                  inputVal={
                    captureSelfiePassport: submissionResponse,
                    passportNumber: extractedData.idNumber,
                    //IssuanceDate: new Date(extractedData.idIssueDate).toISOString().substring(0, 10),
                    IssuanceDate: (extractedData.idIssueDate).split("/").reverse().join("-"),
                    countryOfIssuance: countryOfIssuance,
                    countryCode: extractedData.idIssueCountry,
                    //expirationDate: new Date(extractedData.idExpirationDate).toISOString().substring(0, 10)
                    expirationDate: (extractedData.idExpirationDate).split("/").reverse().join("-"),
                    passportImg: submissionRequest.customerData.idData.idImageFront,
                    passportImgTimestamp: new Date(),
                  }

                  setInputs({
                    ...inputs,
                    ...inputVal
                  });

                  console.log('inputVal', inputVal);

                  if(extractedData.idIssueCountry === "US"){
                    us_citizen = "1";
                  } else {
                    us_citizen = "0";
                  }
                  setInputs((stateInputs) => {
                    console.log(inputs, stateInputs);
                    return stateInputs;
                  });
                  setSuccessPassportDetails(true);
                } else {
                  //alert('null');
                  setErrorPassportDetails(true);
                }


                let hostData = submissionResponse.responseCustomerData.hostData;
                if(hostData != null){
                  console.log('pep', hostData.pepresult.resultCountPEP);
                  console.log('watchlist', hostData.wlsresult.resultCountWLS);
                  console.log('negative media', hostData.nmresult.resultCountNM);
                  
                  if(submissionResponse.resultData.verificationResultCode == '00' && hostData.pepresult.resultCountPEP == '0' && hostData.wlsresult.resultCountWLS == '0' && hostData.nmresult.resultCountNM == '0'){
                    console.log('kyc_approved', 'true');
                    inputVal={
                      ...inputVal,
                      kycApproved:'true'
                    }
                  } else {
                    console.log('kyc_approved', 'false');
                    inputVal={
                      ...inputVal,
                      kycApproved:'false'
                    }
                  }
                
                  if(hostData.pepresult.resultCountPEP == '0'){
                    pep = "0";
                  } else {
                    pep = hostData.pepresult.resultCountPEP;
                  }
                  if(hostData.wlsresult.resultCountWLS == '0'){
                    watchlist = "0";
                  } else {
                    watchlist = hostData.wlsresult.resultCountWLS;
                  }
                  if(hostData.nmresult.resultCountNM == '0'){
                    negative_media = "0";
                  } else {
                    negative_media = hostData.nmresult.resultCountNM;
                  }
                
                  setPPChecks({//for use in secondary doc capture of rc
                    ...ppChecks,
                    us: us_citizen,
                    pep: pep,
                    wls: watchlist,
                    nm: negative_media
                    /* pep: "0",
                    wls: "0",
                    nm: "0" */
                  });
                  
                  inputVal={
                    ...inputVal,
                    captureSelfiePassportChecks: JSON.stringify({
                      ...ppChecks,
                      us: us_citizen,
                      pep: pep,
                      wls: watchlist,
                      nm: negative_media
                    })
                  }
                }

                console.log('inputVal', inputVal);
                setInputs({
                  ...inputs,
                  ...inputVal
                });

                let formdata = {token:token, entity_group:curPageName, items:{
                  captureSelfiePassport: inputVal.captureSelfiePassport,
                  kycApproved: inputVal.kycApproved,
                  passportImg: inputVal.passportImg,
                  passportImgTimestamp: inputVal.passportImgTimestamp,
                  selfieImg: inputs.selfieImg,
                  selfieImgTimestamp: inputs.selfieImgTimestamp,
                  captureSelfiePassportChecks: inputVal.captureSelfiePassportChecks
                }};
                console.log(formdata);

                axios.post(serverConfig.api_base_url + "customer_entity_group_details_set",formdata)
                .then(response=>{
                  console.log('customer_entity_group_details_set response', response);
                  if(response.data.status === 200) {
                    console.log('inputs', inputVal, inputs);
                    console.log(inputs.secondaryDocImg, inputVal.passportImg);
                    if(inputs.secondaryDocImg !== null && inputs.secondaryDocImg !== undefined && inputVal.passportImg !==null && inputVal.passportImg !==undefined){
                      setNextDisabled(false);
                    } else {
                      setNextDisabled(true);
                    }
                  } else if(response.data.status === 407){
                    handleLogout();
                  } else {
                    alert(response.data.message);
                  }
                })
                .catch(err=>console.log("error is",err));

                setErrorPassport(false);
                setStartPPValidation(false);
              }
            }} 
            verifyIdWithExternalDatabases={true} //for watchlist, NM, PEP etc 
            colors={
              {
                loadingOverlay:{
                  continueBtn: {
                    backgroundColor:'#FDC600',
                    textColor: '#000000'
                  }
                }
              }
            }
            geolocationEnabled={false}
            geolocationRequired={false}
            debugMode={true}
          />
        </div>}

        {startSecDocValidation && <div className="validation-popup">
          <IdValidation 
            submissionToken={idmToken} 
            //onBeforeSubmit={(data)=>getSecDocVResponseCallback(data)}
            //idAutoCaptureEnabled={false}
            idCaptureRequirement="idCard"
            //idCardAutoCaptureScoreThreshold="0.01"
            //idCardFocusScoreThreshold="0.01"
            //passportAutoCaptureScoreThreshold="0.01"
            //passportFocusScoreThreshold="0.01"
            //idBackImageRequired={false}
            onExitCapture={() => {setStartSecDocValidation(false)}}
            //onDenied={() => {setErrorPassport(true);}} 
            //sendProcessedImagesInResponse={true}
            //additionalData={true}
            //webhooksEnabled={true}
            //webhooksSendInputImages={true}
            //webhooksSendProcessedImages={true}
            onComplete={(submissionResponse, submissionRequest) => {
              console.log('Your Secondary Doc has been validated', submissionResponse);
              console.log('submissionRequest', submissionRequest);

              var inputVal = {};

              if ('FAIL' === submissionResponse.status || submissionResponse.status.statusCode != "000") {
                console.log("Error capturing secondary ID. Please retry");
                setErrorSecDoc(true);
                setStartSecDocValidation(false);
              } else {
                console.log("SUBMIT SUCCESS. Secondary ID submitted succesfully.");
                setPPChecks({//for use in selfie PP capture of us,nm,pep,wls
                  ...ppChecks,
                  rc: submissionResponse.responseCustomerData.extractedIdData.idCountry
                });

                inputVal={
                  captureIDSecondaryDoc: submissionResponse,
                  secondaryDocImg: submissionRequest.customerData.idData.idImageFront,
                  secondaryDocImgTimestamp: new Date(),
                  secondaryDocBackImg: submissionRequest.customerData.idData.idImageBack,
                  secondaryDocBackImgTimestamp: new Date(),
                  captureSelfiePassportChecks: JSON.stringify({
                    ...ppChecks,
                    rc: submissionResponse.responseCustomerData.extractedIdData.idCountry
                  })
                }
                
                console.log('inputVal', inputVal);
                setInputs({
                  ...inputs,
                  ...inputVal
                });

                let formdata = {token:token, entity_group:curPageName, items:inputVal};
                console.log(formdata);

                axios.post(serverConfig.api_base_url + "customer_entity_group_details_set",formdata)
                .then(response=>{
                  console.log('customer_entity_group_details_set response', response);
                  if(response.data.status === 200) {
                    console.log('inputs', inputVal, inputs);
                    console.log(inputVal.secondaryDocImg, inputs.passportImg);
                    if(inputVal.secondaryDocImg !== null && inputVal.secondaryDocImg !== undefined && inputs.passportImg !==null && inputs.passportImg !==undefined){
                      setNextDisabled(false);
                    } else {
                      setNextDisabled(true);
                    }
                  } else if(response.data.status === 407){
                    handleLogout();
                  } else {
                    alert(response.data.message);
                  }
                })
                .catch(err=>console.log("error is",err));

                setErrorSecDoc(false);
                setStartSecDocValidation(false);
              }
            }} 
            colors={
              {
                loadingOverlay:{
                  continueBtn: {
                    backgroundColor:'#FDC600',
                    textColor: '#000000'
                  }
                }
              }
            }
            geolocationEnabled={false}
            geolocationRequired={false}
            debugMode={true}
          />
        </div>}

        <Modal show={showSubmitModal} onHide={handleCloseSubmitModal} centered backdrop="static" keyboard={false} className="modal-submit">
          <Modal.Body className="text-center pt-5">
            <div>
              <div className="mb-3"><img src={require('./../assets/images/thankyou-icon.png')} alt="Thank you" /></div>
              <p><b>Thank you for completing your KYC</b></p>
              <p>We will review your information and reach out to you if we need further information. Otherwise, you will be cleared in less than 24 hours.</p>
              <p>If you have any questions, you may reach out to our customer support at <b><u><a href="mailto:support@manatoko.com">support@manatoko.com</a></u></b></p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="transparent" onClick={() => navigate("/db")}>Close</Button>
          </Modal.Footer>
        </Modal>

      </div>
    </Container>
  );
};

export default FormSubmit;
